import React from 'react';
import DownloadAppAndroid from 'ferries/helpers/DownloadApp/DownloadAppAndroid';
import DownloadAppIos from 'ferries/helpers/DownloadApp/DownloadAppIos';
import {isIOS, isAndroid} from 'ferries/helpers/native';
import {downloadAppTranslations} from 'helpers/translations';

const {androidAppSlogan, iosAppSlogan, get} = downloadAppTranslations;

const DownloadApp = () => {
  const isAndroidDevice = isAndroid();
  const isIOSDevice = isIOS();

  if (isAndroidDevice) {
    return <DownloadAppAndroid slogan={androidAppSlogan} btnLabel={get} />;
  }

  if (isIOSDevice) {
    return <DownloadAppIos slogan={iosAppSlogan} />;
  }

  return null;
};

export default DownloadApp;
