import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Drawer from 'react-modern-drawer';
import {googleAppLink} from 'ferries/helpers/native';
import logo from 'images/brand/ferryscanner/square.svg';

const DownloadAppAndroid = ({slogan, btnLabel}) => {
  const [isOpen, setIsOpen] = useState(!sessionStorage.getItem('downloadapppromo'));
  const toggleDrawer = () => {
    setIsOpen((isOpen) => !isOpen);
    sessionStorage.setItem('downloadapppromo', true);
  };

  return (
    <Drawer
      open={isOpen}
      enableOverlay={true}
      lockBackgroundScroll={true}
      direction='bottom'
      size="74px"
      zIndex={400}
      onClose={toggleDrawer}
    >
      <div className="my-3 mx-4">
        <div className="grid small-gutter align-items-center">
          <div className="grid-item-auto">
            <img width="42" height="42" src={logo} />
          </div>
          <div className="grid-item leading-small">
            <div className="text-small text-primary font-bold">{slogan}</div>
          </div>
          <div className="grid-item-auto">
            <a href={googleAppLink} className="text-decoration-none">
              <div className="bg-primary border-radius-lg px-3 py-2 text-white">
                {btnLabel}
              </div>
            </a>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

DownloadAppAndroid.propTypes = {
  slogan: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired
};

export default DownloadAppAndroid;
