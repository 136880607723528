import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Drawer from 'react-modern-drawer';
import {brand} from 'helpers/stableValues';
import {capitalize} from 'ferries/helpers/common';
import {appleStoreLink} from 'ferries/helpers/native';
import logo from 'images/brand/ferryscanner/curved.svg';
import downloadImage from 'images/social/ios-download.png';

const DownloadAppIos = ({slogan}) => {
  const [isOpen, setIsOpen] = useState(!sessionStorage.getItem('downloadapppromo'));
  const toggleDrawer = () => {
    setIsOpen((isOpen) => !isOpen);
    sessionStorage.setItem('downloadapppromo', true);
  };

  return (
    <Drawer
      open={isOpen}
      enableOverlay={true}
      lockBackgroundScroll={true}
      direction='top'
      size="94px"
      zIndex={400}
      onClose={toggleDrawer}
    >
      <div className="my-3 mx-4">
        <div className="grid small-gutter align-items-center">
          <div className="grid-item-auto">
            <i className="icon icon-sm icon-e-remove-1 text-muted" onClick={toggleDrawer} />
          </div>
          <div className="grid-item-auto">
            <img width="62" height="62" src={logo} />
          </div>
          <div className="grid-item leading-small">
            {capitalize(brand)}
            <div className="text-muted">{slogan}</div>
          </div>
          <div className="grid-item-auto cursor-pointer">
            <a href={appleStoreLink}>
              <img width="24" height="24" src={downloadImage} />
            </a>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

DownloadAppIos.propTypes = {
  slogan: PropTypes.string.isRequired
};

export default DownloadAppIos;
