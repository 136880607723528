import Translation from 'lib/helpers/translation';

export const mainTranslations = {
  ...Translation.group('main')
};

export const navbarTranslations = {
  headerContact: Translation.tr('headerContact', 'header'),
  blog: Translation.tr('blog', 'header'),
  customerService: Translation.tr('customerService', 'header'),
  flightsATHeader: Translation.tr('at_header_flights', 'header'),
  hotelsATHeader: Translation.tr('at_header_hotels', 'header'),
  'f&hATHeader': Translation.tr('at_header_f&h', 'header'),
  carsATHeader: Translation.tr('at_header_cars', 'header'),
  ferriesATHeader: Translation.tr('at_header_ferries', 'header'),
  cruisesATHeader: Translation.tr('at_header_cruises', 'header'),
  't&aATHeader': Translation.tr('at_header_tours&activities', 'header'),
  languageOptions: Translation.tr('languageOptions', 'header'),
  currencyOptions: Translation.tr('currencyOptions', 'header'),
  save: Translation.tr('save', 'header'),
  submit: Translation.tr('submit', 'header'),
  login: Translation.tr('login', 'header'),
  register: Translation.tr('register', 'header'),
  viewDashboard: Translation.tr('viewDashboard', 'header'),
  firstnameAccount: Translation.tr('firstnameAccount', 'header'),
  logout: Translation.tr('logout', 'header'),
  logoutSuccessful: Translation.tr('logoutSuccessful', 'header'),
  welcomeUser: Translation.tr('welcomeUser', 'header'),
  new: Translation.tr('new', 'header'),
  userBtnTooltipTitle: Translation.tr('userBtnTooltipTitle', 'header'),
  userBtnTooltipBody: Translation.tr('userBtnTooltipBody', 'header'),
  letsChat: Translation.tr('letsChat', 'header'),
  myBooking: Translation.tr('myBooking', 'heaader'),
  regionalSettings: Translation.tr('regionalSettings', 'heaader'),
  switchTo: Translation.tr('switchTo', 'heaader')
};

export const searchMaskTranslations = {
  ...Translation.group('searchMask')
};

export const uspTranslations = {
  ...Translation.group('usp')
};

export const newsletterTranslations = {
  title: Translation.tr('title', 'newsletter'),
  description: Translation.tr('description', 'newsletter'),
  enterMail: Translation.tr('enterMail', 'newsletter'),
  buttonText: Translation.tr('buttonText', 'newsletter'),
  emailLabel: Translation.tr('email', 'newsletter'),
  thanksSubscribing: Translation.tr('thanksSubscribing', 'newsletter')
};

export const footerTranslations = {
  contact: {
    contactDetails: Translation.tr('contactDetails', 'footer'),
    footerContact: Translation.tr('footerContact', 'footer'),
    companyStreetAndNumber: Translation.tr('companyStreetAndNumber', 'footer'),
    companyCityAndPostCode: Translation.tr('companyCityAndPostCode', 'footer'),
    workdaysFooter: Translation.tr('workdaysFooter', 'footer')
  },
  payment: {
    owner: Translation.tr('bankAccountOwner', 'footer'),
    number: Translation.tr('bankAccountNumber', 'footer')
  },
  seo: {
    text: Translation.tr('seoText', 'footer')
  },
  internationalSites: {
    text: Translation.tr('internationalSites', 'footer')
  },
  newsFeed: {
    title: Translation.tr('asSeenOn', 'footer')
  },
  followUs: {
    title: Translation.tr('followUs', 'footer')
  }
};

export const getAppTranslations = {
  ...Translation.group('getApp')
};

export const memberTranslations = {
  translations: {
    title: Translation.tr('becomeMember', 'footer'),
    description: Translation.tr('becomeMemberDescription', 'footer'),
    buttonText: Translation.tr('Sign up', 'authentication'),
    footerLoggedIn: Translation.tr('footerLoggedIn', 'footer')
  }
};

export const topOffersTranslations = {
  ...Translation.group('topOffers')
};

export const directivesTranslations = {
  ...Translation.group('directives')
};

export const airlineHeroTranslations = {
  flightTickets: Translation.tr('flightTickets', 'hero'),
  lowestFares: Translation.tr('lowestFares', 'hero')
};

export const airlineSeoTranslations = {
  ...Translation.group('seo')
};

export const partnerRedirectTranslations = {
  'partnerRedirect': Translation.tr('partnerRedirect', 'partner'),
  'errorRedirectHeader': Translation.tr('waitingError', 'partner'),
  'errorRedirectCta': Translation.tr('waitingErrorCta', 'partner'),
  'urlRedirectMessage': Translation.tr('urlRedirectMessage', 'partner')
};

export const partnerFerryRedirectTranslations = {
  'partnerRedirect': Translation.tr('partnerRedirect', 'partner'),
  'errorRedirectHeader': Translation.tr('waitingError', 'partner'),
  'errorRedirectHome': Translation.tr('waitingErrorHome', 'partner'),
  'urlRedirectMessage': Translation.tr('urlRedirectMessage', 'partner')
};

export const gdprPreferencesTranslations = {
  ...Translation.group('gdpr')
};

export const cookieConsentTranslations = {
  ...Translation.group('cookieConsent')
};

export const subscribeTranslations = {
  ...Translation.group('subscribe')
};

export const personalDetailsTranslations = {
  ...Translation.group('errorMessages')
};

export const resultsTranslations = {
  ...Translation.group('results')
};

export const downloadAppTranslations = {
  ...Translation.group('downloadApp')
};
